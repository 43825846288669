import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { rtkApiRequest } from 'src/shared/api/api';
import { RequestStatus, ResponseStatus } from 'src/shared/api/types';
import { wait } from 'src/shared/lib/async';
import { IPagination } from 'src/shared/ui/Pagination/types';
import { MetaItem, WidgetGetList, WidgetGetStats, WidgetList, WidgetStats } from './types';

const WAIT_FOR_RESPONSE_TIME = 3000;

const getMetadata = createAsyncThunk(`qc/metadata`, async (_: void, thunkAPI) => {
	return await rtkApiRequest.rtkGETRequest<MetaItem[]>({
		customHost: 'qc',
		url: 'metadata',
		thunkAPI,
	});
});

const getStats = createAsyncThunk(`qc/stats`, async (args: WidgetGetStats, thunkAPI) => {
	const { params, qcType } = args;
	const { signal } = thunkAPI;

	let res;
	let status: ResponseStatus;
	let queryId: number | null = null;

	do {
		res = await rtkApiRequest.rtkGETRequest<WidgetStats>({
			customHost: 'qc',
			url: `qc/${qcType}/stats`,
			params: {
				...params,
				...(queryId != null ? { query_id: queryId } : {}),
			},
			thunkAPI,
			signal,
		});

		queryId = res.queryId ?? null;
		status = res.status as ResponseStatus;

		if (queryId && status === 'InProgress' && signal?.aborted === false) {
			await wait(WAIT_FOR_RESPONSE_TIME);
		}
	} while (status === 'InProgress' && signal?.aborted === false);
	return res;
});

const getList = createAsyncThunk(`qc/list`, async (args: WidgetGetList, thunkAPI) => {
	const { params, qcType } = args;
	const { signal } = thunkAPI;

	let res;
	let status: ResponseStatus;
	let queryId: number | null = null;

	do {
		res = await rtkApiRequest.rtkGETRequest<WidgetList>({
			customHost: 'qc',
			url: `qc/${qcType}/list`,
			params: {
				...params,
				...(queryId != null ? { query_id: queryId } : {}),
			},
			thunkAPI,
			signal,
		});

		queryId = res.queryId ?? null;
		status = res.status as ResponseStatus;

		if (queryId && status === 'InProgress' && signal?.aborted === false) {
			await wait(WAIT_FOR_RESPONSE_TIME);
		}
	} while (status === 'InProgress' && signal?.aborted === false);
	return res;
});

interface State {
	metaData: MetaItem[];
	widgetsStats: WidgetStats[];
	widgetsLists: WidgetList[];

	showWidgets: MetaItem[];

	widget: string;

	pagination: IPagination;
	status: {
		meta: RequestStatus;
		stats: RequestStatus;
		list: RequestStatus;
	};
}

export const initialState: State = {
	metaData: [],
	widgetsStats: [],
	widgetsLists: [],

	showWidgets: [],

	widget: '',

	pagination: {
		pageSize: 10,
		pageIndex: 0,
		pageCount: 1,
	},

	status: {
		meta: RequestStatus.still,
		stats: RequestStatus.still,
		list: RequestStatus.still,
	},
};

export const slice = createSlice({
	name: 'qcDashboard_widgets',
	initialState,
	reducers: {
		clearAllStats: state => {
			state.widgetsStats = [];
		},
		clearSelectedList: (state, action: { payload: string }) => {
			state.widgetsLists = state.widgetsLists.filter(list => list.widgetName !== action.payload);
		},
		setPagination: (state, action: PayloadAction<{ pagination: IPagination }>) => {
			state.pagination = action.payload.pagination;
		},
	},
	extraReducers: builder => {
		builder.addCase(getMetadata.pending, state => {
			state.status.meta = RequestStatus.loading;
		});
		builder.addCase(getMetadata.fulfilled, (state, action) => {
			// Add sorted widgets by loadOrder, if there is no loadOrder, then load them first
			const metaData = action.payload.sort((a, b) => {
				const ax = a.loadOrder ?? 0;
				const bx = b.loadOrder ?? 0;
				return ax - bx;
			});
			state.metaData = metaData;
			state.status.meta = RequestStatus.still;
		});
		builder.addCase(getMetadata.rejected, state => {
			state.status.meta = RequestStatus.failed;
		});

		builder.addCase(getStats.pending, state => {
			state.status.stats = RequestStatus.loading;
		});
		builder.addCase(getStats.fulfilled, (state, action) => {
			const { qcType } = action.meta.arg;

			state.widgetsStats = [...state.widgetsStats.filter(element => element.result?.widgetName !== qcType)];
			state.widgetsStats = [...state.widgetsStats, action.payload];
			state.status.stats = RequestStatus.still;
		});
		builder.addCase(getStats.rejected, (state, action) => {
			const { aborted } = action.meta;
			if (!aborted) {
				state.status.stats = RequestStatus.failed;
			}
		});

		builder.addCase(getList.pending, state => {
			state.status.list = RequestStatus.loading;
		});
		builder.addCase(getList.fulfilled, (state, action) => {
			const { qcType, options } = action.meta.arg;

			if (!options?.ignoreStore) {
				state.widgetsLists = [...state.widgetsLists.filter(element => element.widgetName !== qcType)];
				state.widgetsLists = [...state.widgetsLists, { widgetName: qcType, ...action.payload } as WidgetList];
			}

			state.status.list = RequestStatus.still;
		});
		builder.addCase(getList.rejected, (state, action) => {
			const { aborted } = action.meta;
			if (!aborted) {
				state.status.list = RequestStatus.failed;
			}
		});
	},
});

export const actionsQcMeta = {
	...slice.actions,
	getMetadata,
	getStats,
	getList,
};
