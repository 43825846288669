import Exceljs, { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { FC } from 'react';
import { ButtonPrimary } from 'src/shared/ui/_buttons/ButtonPrimary';
import { CompaniesListItem, DuplicatesListItem, PeopleListItem } from '../../_BLL/types';
import { isDuplicatesList, isPeopleList } from '../QCContent/typeGuards';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'src/app/redux/createAction';
import { actionsQcMeta } from '../../_BLL/slice';
import { useUrlFilterParams } from '../../hooks/useUrlFilterParams';
import { generateWidgetParams } from '../../lib/generateStatsParams/generateStatsParams';

const generateColumns = (list: CompaniesListItem[] | PeopleListItem[] | DuplicatesListItem[]) => {
	const baseColumns = [
		{ header: 'DEI ID', key: 'companyId', width: 10 },
		{ header: 'Company legal name', key: 'legalName', width: 60 },
	];

	if (isPeopleList(list)) {
		const columns = [
			...baseColumns, //
			{ header: 'Person name', key: 'personName', width: 20 },
			{ header: 'Role', key: 'role', width: 20 },
		];

		// For strange titles
		if ('title' in list[0]) {
			columns.push({ header: 'Title', key: 'title', width: 20 });
		}

		// For strange education institutions
		if ('educationInstitution' in list[0]) {
			columns.push({ header: 'Education institution', key: 'educationInstitution', width: 40 });
		}

		return columns;
	}

	if (isDuplicatesList(list)) {
		return [
			baseColumns[0],
			{ header: 'Company name', key: 'companyName', width: 20 },
			{ header: 'People names', key: 'peopleNames', width: 50 },
			{ header: 'Count', key: 'count', width: 20 },
		];
	}

	return baseColumns;
};

interface Props {
	count: number;
}

export const ExportList: FC<Props> = props => {
	const { count } = props;

	const params = useParams() as { widget: string };
	const urlSearchParams = useUrlFilterParams();

	const widgetName = params.widget;

	// TODO: Move the function UP.
	const widgetParams = generateWidgetParams({
		...urlSearchParams,
		pagination: {
			pageIndex: 0,
			pageSize: count,
			pageCount: 1,
		},
	});

	// * Actions
	const dispatch = useAppDispatch();
	const { getList } = actionsQcMeta;

	const saveWorkbook = (workbook: Workbook, fileName: string) => {
		workbook.xlsx.writeBuffer().then(data => {
			const blob = new Blob([data], { type: '' });
			saveAs(blob, fileName);
		});
	};

	const onExportClick = () => {
		dispatch(getList({ params: widgetParams, qcType: widgetName, options: { ignoreStore: true } }))
			.unwrap()
			.then(res => {
				const companies = res.result?.companies ?? [];
				const people = res.result?.people ?? [];
				const duplicates = res.result?.duplicates ?? [];

				let list: CompaniesListItem[] | PeopleListItem[] | DuplicatesListItem[] = [];

				if (companies.length > 0) {
					list = [...companies];
				}

				if (people.length > 0) {
					list = [...people];
				}

				if (duplicates.length > 0) {
					list = [...duplicates];
				}

				const workbook = new Exceljs.Workbook();
				const worksheet = workbook.addWorksheet('log');
				worksheet.properties.defaultRowHeight = 20;

				const columns = generateColumns(list);

				worksheet.columns = columns;
				worksheet.addRows(list);
				saveWorkbook(workbook, 'log.xlsx');
			})
			.catch(err => console.log(err));
	};

	// * Render
	return <ButtonPrimary onClick={onExportClick}>Export full list</ButtonPrimary>;
};
