import { CompaniesListItem, PeopleListItem, DuplicatesListItem } from '../../_BLL/types';

export const isCompaniesList = (list: CompaniesListItem[] | PeopleListItem[] | DuplicatesListItem[]): list is CompaniesListItem[] => {
	const listItem = list[0];
	return !('personName' in listItem) && !('peopleNames' in listItem);
};

export const isPeopleList = (list: CompaniesListItem[] | PeopleListItem[] | DuplicatesListItem[]): list is PeopleListItem[] => {
	return 'personName' in list[0];
};

export const isDuplicatesList = (list: CompaniesListItem[] | PeopleListItem[] | DuplicatesListItem[]): list is DuplicatesListItem[] => {
	return 'peopleNames' in list[0];
};
